import { render, staticRenderFns } from "./TopSubNavigation.vue?vue&type=template&id=47e64226&scoped=true"
import script from "./TopSubNavigation.vue?vue&type=script&lang=js"
export * from "./TopSubNavigation.vue?vue&type=script&lang=js"
import style0 from "./TopSubNavigation.vue?vue&type=style&index=0&id=47e64226&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e64226",
  null
  
)

export default component.exports