<template>
  <nav class="top-nav">
      <ul ref="navList">
        <li
          v-for="section in sections"
          :key="section.id"
          class="mx-1 text-primary"
          @click.prevent="scrollToSection(section.id)"
        >
          {{ section.description }}
        </li>
      </ul>
  </nav>
  <!-- :class="{ active: section.id === activeSection }" -->
</template>

<script>
export default {
    props: {
        sections: {
            type: Array,
            required: true
        },
        activeSection: {
            type: String
        }
    },
    methods: {
        scrollToSection(id) {
            const section = document.getElementById(id);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        },
        scrollToActiveSection() {
            const activeItem = this.$refs.navList.querySelector('.active');
            if (activeItem) {
                const nav = this.$refs.navList;
                const navRect = nav.getBoundingClientRect();
                const activeItemRect = activeItem.getBoundingClientRect();

                if (activeItemRect.left < navRect.left || activeItemRect.right > navRect.right) {
                    activeItem.scrollIntoView({
                        behavior: 'smooth',
                        inline: 'center'
                    });
                }
            }
        },
        scrollLeft() {
            this.$refs.navList.scrollBy({ left: -200, behavior: 'smooth' });
        },
        scrollRight() {
            this.$refs.navList.scrollBy({ left: 200, behavior: 'smooth' });
        }
    }
};
</script>

<style scoped>
.top-nav {
  background-color: #fff;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 990;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.top-nav ul {
  list-style-type: none;
  display: flex;
  padding: 10px 0;
  margin: 0;
  overflow-x: auto;
  scroll-behavior: smooth;
  /* Hide scrollbar */
}

.top-nav ul::-webkit-scrollbar {
  display: none;
}

.top-nav li {
  color: var(--bs-primary) !important;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  border: 2px solid var(--bs-primary);
}

.top-nav li:hover {
  color: #fff !important;
  background-color: var(--bs-primary);
  transition: 0.3s;
}

.top-nav li.active {
  color: #fff !important;
  background-color: var(--bs-primary);
  transition: 0.3s;
}

/* Затемнение по краям */
.top-nav::before,
.top-nav::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px; /* Ширина затемнения */
  pointer-events: none; /* Чтобы не блокировать клики */
  z-index: 1;
}

.top-nav::before {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.top-nav::after {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

</style>
