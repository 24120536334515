<template>
    <div>
        <header-with-line :header="'Unsere Therapieangebote'" class="mb-4" />
        <div class="row p-0 text-center d-flex justify-content-center align-items-center">
            <div class="col-xl-3 col-sm-12 col-md-6 col-lg-6 p-0">
                <service-card
                    :title="'Logo-<br>pädie'"
                    :imgSrc="require('../../assets/img/therapy_services/speech_therapy.png')"
                    :imgAlt="'Logopädie'"
                    :link="'/leistungen#speech-therapy'"
                />
            </div>
            <div class="col-xl-3 col-sm-12 col-md-6 col-lg-6 p-0">
                <service-card
                    :title="'Physio-<br>therapie'"
                    :imgSrc="require('../../assets/img/therapy_services/physiotherapy.png')"
                    :imgAlt="'Physiotherapie'"
                    :link="'/leistungen#physio-therapy'"
                />
            </div>
            <div class="col-xl-3 col-sm-12 col-md-6 col-lg-6 p-0">
                <service-card
                    :title="'Ergo-<br>therapie'"
                    :imgSrc="require('../../assets/img/therapy_services/occupational_therapy.png')"
                    :imgAlt="'Ergotherapie'"
                    :link="'/leistungen#ocupational-therapy'"
                />
            </div>
            <div class="col-xl-3 col-sm-12 col-md-6 col-lg-6 p-0">
                <service-card
                    :title="'Neuro-<br>Reha-<br>bilitation'"
                    :imgSrc="require('../../assets/img/therapy_services/neurorehabilitation.png')"
                    :imgAlt="'Neurorehabilitation'"
                    :link="'/leistungen#neuro-rehabilitation'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ServiceCard from '../Ui/ServiceCard.vue';
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'TherapyServices',
    components: {
        ServiceCard,
        HeaderWithLine
    },
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
};
</script>

<style scoped>
.custom-text-001 {
    font-family: 'HP Simplified';
}

.custom-text-002 {
    font-family: 'HP Simplified';
    color: white;
    font-size: 4rem;
    -webkit-text-stroke: 4px #08425e;
}

.custom-text-004 {
    font-family: 'HP Simplified';
    font-size: 100px;
    white-space: nowrap;
}

.colored-block {
    background-color: #829CAA;
    border-top: 5px solid #08425e;
    border-bottom: 5px solid #08425e    ;
    padding: 20px;
    position: relative;
    text-align: center;
}
/*
@media screen and (max-width: 992px) {
    .custom-text-003 {
        font-size: 2vw;
        -webkit-text-stroke: 0.1vw #08425e;
    }
}

@media screen and (max-width: 768px) {
    .custom-text-003 {
        font-size: 4vw;
        -webkit-text-stroke: 0.2vw #08425e;
    }
}

@media screen and (max-width: 576px) {
    .custom-text-003 {
        font-size: 5vw;
        -webkit-text-stroke: 0.3vw #08425e;
    }
}
*/
</style>
