import { render, staticRenderFns } from "./OurTeam.vue?vue&type=template&id=df56f568&scoped=true"
import script from "./OurTeam.vue?vue&type=script&lang=js"
export * from "./OurTeam.vue?vue&type=script&lang=js"
import style0 from "./OurTeam.vue?vue&type=style&index=0&id=df56f568&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df56f568",
  null
  
)

export default component.exports