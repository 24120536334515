<template>
    <div>
        <div class="row text-center">
            <div class="col-12">
                <img loading="lazy" class="mb-5 img-logo" src="../../assets/img/partners/seniorenhof.png" alt="Seniorenhof Schlossberg">
            </div>
            <div class="col-12">
                <img loading="lazy" class="mb-5 img-deko" src="../../assets/img/decorations/line_green_horizontal.png" alt="Liniendekoration">
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12">
                <img loading="lazy" class="mb-5 img-logo" src="https://caritas-wohnenundpflege.de/wp-content/uploads/2023/01/logo_caritas-wohnen-pflege.svg" alt="Caritas Wohnen und Pflege">
            </div>
            <div class="col-12">
                <img loading="lazy" class="mb-5 img-deko" src="../../assets/img/decorations/line_green_horizontal.png" alt="Liniendekoration">
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12">
                <img loading="lazy" class=" mb-5 img-logo" src="../../assets/img/partners/drlidiasamanski.png" alt="Dr. Lidia Samanski">
            </div>
            <div class="col-12">
                <img loading="lazy" class="mb-5 img-deko" src="../../assets/img/decorations/line_green_horizontal.png" alt="Liniendekoration">
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12">
                <img loading="lazy" class="mb-5 img-logo" src="https://www.diakoneo.de/typo3conf/ext/auw_project/Resources/PubStatic/mandant/img/logo.svg" alt="Diakoneo">
            </div>
            <div class="col-12">
                <img loading="lazy" class="mb-5 img-deko" src="../../assets/img/decorations/line_green_horizontal.png" alt="Liniendekoration">
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12">
                <img loading="lazy" class="mb-5 img-logo" src="../../assets/img/partners/diakonieansbach.png" alt="Seniorenhof Schlossberg">
            </div>
            <div class="col-12">
                <img loading="lazy" class="mb-5 img-deko" src="../../assets/img/decorations/line_green_horizontal.png" alt="Liniendekoration">
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12">
                <img loading="lazy" class="mb-5 img-logo" src="https://www.anettes-wellness.de/wp-content/uploads/2021/03/Anettes-Wellness-Logo.png" alt="Anettes Wellness">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
};
</script>
<style>
.img-logo {
    max-width: 40%;
    height: auto;
}
.img-deko {
    max-width: 70%;
}

@media (max-width: 768px) {
    .img-logo {
        max-width: 70%;
    }
    .img-deko {
        max-width: 90%;
    }
}
</style>
