<template>
  <button
    v-if="showScrollTop"
    @click="scrollToTop"
    class="scroll-to-top"
  >
    <i class="fas fa-arrow-up"></i>
  </button>
</template>

<script>
export default {
    name: 'ScrollToTopButton',
    data() {
        return {
            showScrollTop: false
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.showScrollTop = window.scrollY > 200;
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
};
</script>

<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--bs-tertiary);
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s, transform 0.3s;
}

.scroll-to-top:hover {
  background-color: var(--bs-primary);
  transition: 0.3s;
}

.scroll-to-top:active {
  transform: scale(0.95);
}
</style>
