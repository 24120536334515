<template>
    <div>
        <img class="img-fluid img-container mb-3" src="../../assets/img/on_the_good_road.jpg" alt="Fleckdekoration">
        <div style="position: relative; display: inline-block;">
            <img class="img-fluid mb-3" src="../../assets/img/decorations/our_heart_desire.svg" alt="Fleckdekoration">
        </div>
        <div class="text-container text-center mb-5">
            <div class="text-muted fs-3"><span class="text-primary">Wir wissen: </span>Oft benötigen die kleinen und großen Patienten nur einen Anstoß, manchmal aber auch eine intensive Therapie, um Erfolge oder das gesetzte Ziel zu erreichen.</div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
};
</script>

<style scoped>

.custom-text-001 {
    font-family: 'HP Simplified';
    color: white;
    font-size: 40px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    -webkit-text-stroke: 2px #08425e;
}

.text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-container {
    border: 1px solid var(--bs-tertiary);
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

@media  screen and (max-width: 1199px){
    .custom-text-001
    {
        font-size: 2.7vw;
        -webkit-text-stroke: 0.1vw #08425e;
    }
}

@media screen and (max-width: 991px) {
    .custom-text-001 {
        font-size: 4.3vw;
        -webkit-text-stroke: 0.2vw #08425e;
    }
}

@media screen and (max-width: 767px) {
    .custom-text-001 {
        font-size: 4vw;
        -webkit-text-stroke: 0.2vw #08425e;
    }
}

@media screen and (max-width: 576px) {
    .custom-text-001 {
        font-size: 5.7vw;
        -webkit-text-stroke: 0.3vw #08425e;
    }
}
</style>
