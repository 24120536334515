<template>
  <div>
    <b-carousel
      v-model="slide"
      :interval="4000"
      controls
      fade
      indicators
      background="#ffffffff"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      >
      <b-carousel-slide v-for="(img, index) in images" :key="index" >
        <template #img>
          <img :src="img" class="d-block img-fluid w-100" :alt="'Slide ' + (index + 1)" />
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import tourBild1 from '../../assets/img/tour/01.jpg';
import tourBild2 from '../../assets/img/tour/02.jpg';
import tourBild3 from '../../assets/img/tour/03.jpg';
import tourBild4 from '../../assets/img/tour/04.jpg';
import tourBild5 from '../../assets/img/tour/05.jpg';
import tourBild6 from '../../assets/img/tour/06.jpg';
import tourBild7 from '../../assets/img/tour/07.jpg';
import tourBild8 from '../../assets/img/tour/08.jpg';
import tourBild9 from '../../assets/img/tour/09.jpg';
import tourBild10 from '../../assets/img/tour/10.jpg';
import tourBild11 from '../../assets/img/tour/11.jpg';
import tourBild12 from '../../assets/img/tour/12.jpg';
import tourBild13 from '../../assets/img/tour/13.jpg';
import tourBild14 from '../../assets/img/tour/14.jpg';
import tourBild15 from '../../assets/img/tour/15.jpg';
import tourBild16 from '../../assets/img/tour/16.jpg';
import tourBild17 from '../../assets/img/tour/17.jpg';
import tourBild18 from '../../assets/img/tour/18.jpg';
import tourBild19 from '../../assets/img/tour/19.jpg';
import tourBild20 from '../../assets/img/tour/20.jpg';

export default {
    data() {
        return {
            slide: 0,
            sliding: null,
            images: [
                tourBild1,
                tourBild2,
                tourBild3,
                tourBild4,
                tourBild5,
                tourBild6,
                tourBild7,
                tourBild8,
                tourBild9,
                tourBild10,
                tourBild11,
                tourBild12,
                tourBild13,
                tourBild14,
                tourBild15,
                tourBild16,
                tourBild17,
                tourBild18,
                tourBild19,
                tourBild20
            ]
        };
    },
    methods: {
        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        }
    }
};
</script>
