import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import VueAxios from 'vue-axios';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faClock,
    faSearch,
    faTimes,
    faPhone,
    faChevronRight,
    faEnvelope,
    faPaperPlane,
    faMapMarkerAlt,
    faPlusCircle,
    faHandshake
} from '@fortawesome/free-solid-svg-icons';
import {
    faInstagram,
    faTiktok,
    faYoutube,
    faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faArchive } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import store from './store';
import router from './router';
import App from './App.vue';
import i18n from './i18n';

library.add(faClock, faSearch, faTimes, faPhone, faChevronRight, faEnvelope, faInstagram, faYoutube, faTiktok, faCircle, faPaperPlane, faMapMarkerAlt, faArchive, faWhatsapp, faPlusCircle, faHandshake);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(VueAxios);
Vue.use(VueI18n);
Vue.use(BootstrapVue);

new Vue({
    library,
    FontAwesomeIcon,
    router,
    store,
    BootstrapVue,
    IconsPlugin,
    i18n,
    render: (h) => h(App)
}).$mount('#app');
