export function collectSections() {
    const sectionElements = document.querySelectorAll('section[id]');
    if (sectionElements && sectionElements.length) {
        return Array.from(sectionElements).map((section) => ({
            id: section.id,
            description: section.getAttribute('data-description')
        }));
    }
    console.error('sections is not defined or is empty');
    return [];
}

export function observeSections(sections, callback) {
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    callback(entry.target.id);
                }
            });
        },
        {
            threshold: 0.1
        }
    );
    sections.forEach((section) => {
        const element = document.getElementById(section.id);
        if (element) {
            observer.observe(element);
        }
    });
}
