<template>
    <div class="title-container">
        <div class="custom-text-001 text-white text-uppercase">unser team</div>
        <img class="mb-4" src="../../assets/img/decorations/line_green_horizontal.png" alt="Liniendekoration">
        <p class="custom-text-002 fs-1 text-bold">Wir sind für DICH da</p>
    </div>
</template>
<script>
export default {
    name: 'OurTeam',
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
};
</script>
<style scoped>
.title-container {
    margin-inline: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-text-001 {
    font-family: 'HP Simplified';
    font-size: 114px;
    -webkit-text-stroke: 5px #08425e;
}

.custom-text-002 {
    color: var(--bs-background);
}

.title-container img {
    width: 70%; /* Adjust the percentage to match the desired width */
    margin: 10px; /* Adjust the margin as needed */
    min-width: 100px; /* Set a minimum width to prevent the image from getting too small */
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
}
</style>
