<template>
    <div class="container">
      <header-with-line header="Unser Leitbild" class="mb-5"></header-with-line>
        <b-container fluid class="parallax-zoom-section">
        </b-container>
        <!--
        <div class="text-center">
            <img class="img-fluid" src="../../assets/img/leitbild.png" alt="Unsere Leitbild">
        </div>
        -->
        <div class="row py-1">
            <div class="col-lg-9 mx-auto text-center mb-3">
                <div class="text-primary fs-3">Das Viavitum steht frei übersetzt für "über das Leben oder den Lebensweg"</div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
              <div class="text-justify fs-4">
                <p v-html="isExpanded ? fullText : shortText"></p>
              </div>
              <div class="text-center pt-2">
                <b-button v-if="!isExpanded" class="text-white bg-primary px-5" pill @click="toggleExpand">...weiter lesen</b-button>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import { BButton } from 'bootstrap-vue';
import HeaderWithLine from '@/components/Ui/HeaderWithLine.vue';

export default {
    name: '',
    components: {
        BButton,
        HeaderWithLine
    },
    data() {
        return {
            isExpanded: false,
            fullText: `Das Viavitum ist davon überzeugt, dass das Leben mehr bietet als Krankheit und Gesundheit - es findet dazwischen statt.
              Unsere Mission als interdisziplinäres Therapiezentrum besteht darin, durch Ethik, Wertschätzung, Respekt und Liebe in unseren Fachbereichen Großartiges für unsere Patienten zu leisten.
              Wir machen das Leben spürbar anders! Durch unsere therapeutische Vielfältigkeit, die wir auf der Grundlage unseres interdisziplinären Austauschs ermöglichen, steigern wir die Lebensqualität unserer Patienten.
              Neueste Evidenz und modernste Technik sind maßgeblich, um unseren Patienten Therapien auf dem höchsten Stand bieten zu können.
              Wir möchten ein Bewusstsein schaffen, dass unsere Patienten eigenverantwortlich handeln können.
              Dies bedeutet, sich selbst zu reflektieren und zu erkennen, dass man selbst wertvoll und richtig ist, so wie man ist, und dass alle Ressourcen in einem sind, um alles zu schaffen.
              Dadurch können wir nicht nur menschlich, sondern auch therapeutisch vorankommen und persönlichen Stillstand nicht akzeptieren.
              Unser Ziel ist es, die größte Praxis in der Region um Ansbach zu sein, die Menschen jeglicher sozialer Identität eine exzellente und qualitativ hochwertige Neurorehabilitation bietet.`,
            shortText: `Das Viavitum ist davon überzeugt, dass das Leben mehr bietet als Krankheit und Gesundheit - es findet dazwischen statt.
                        Unsere Mission als interdisziplinäres Therapiezentrum besteht darin, durch Ethik, <span class="text-muted"> Wertschätzung, Respekt und Liebe in unseren Fachbereichen Großartiges für unsere Patienten zu leisten.
                        Wir machen das Leben spürbar anders! Durch unsere therapeutische Vielfältigkeit, die wir auf ...</span>`
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        },
        handleScroll() {
            const scrollPosition = window.scrollY;
            const zoomFactor = 100 + scrollPosition / 500; // Hier kann man Zoom Faktor anpassen
            const parallaxSection = document.querySelector('.parallax-zoom-section');
            parallaxSection.style.backgroundSize = `auto ${zoomFactor}%`; // Hier wird der Zoom Faktor angewendet
        }
    },
    computed: {
    }
};
</script>
<style scoped>
.custom-text-001 {
    font-family: 'HP Simplified';
    font-size: 90px;
    white-space: nowrap;
}
button {
    border: 0.3rem solid #b0c34a;
}

button:hover {
    background-color: #07425e !important;
    border: 0.3rem solid #b0c34a;
}

.img-border {
    border: 4px solid var(--bs-tertiary);
    border-radius: 30px;
}

.parallax-zoom-section {
    background-image: url('../../assets/img/leitbild.png');
    height: 400px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-size 0.2s ease-out; /* Smooth Zoom Effekt */
}

@media screen and (max-width: 1366px) {
    .parallax-zoom-section {
        height: 50vw;
        background-attachment: scroll;
    }
}

.parallax-zoom-section h1,
.parallax-zoom-section p {
  color: white;
}
</style>
