<template>
    <b-card overlay
      loading="lazy"
      :img-src="computedImgSrc"
      :img-alt="imgAlt"
      img-top
      class="text-white text-center"
    >
    <div v-html="title" class="card-title"></div>
    <router-link :to="link" exact v-slot="{ navigate, isActive }" custom>
      <p @click="navigate" :class="{ active: isActive }" class="text-block rounded-pill">
        ... mehr erfahren
      </p>
    </router-link>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import blanccoImage from '../../assets/img/decorations/shild_blanco.png';

export default {
    name: 'ServiceCard',
    components: {
        BCard
    },
    props: {
        title: {
            type: String,
            required: true
        },
        imgSrc: {
            type: String,
            required: false
        },
        imgAlt: {
            type: String,
            required: true
        },
        link: {
            type: String,
            required: false,
            default: '/'
        }
    },
    data() {
        return {
            blanccoImage: blanccoImage
        };
    },
    computed: {
        computedImgSrc() {
            return this.imgSrc || this.blanccoImage;
        }
    },
    mounted() {
    },
    methods: {
    }
};
</script>

<style scoped>
.card-title {
    font-family: 'HP Simplified';
    color: white;
    font-size: 50px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-text-stroke: 2px #08425e;
}

.text-block {
    font-weight: bold;
    color: var(--bs-text);
    background-color: var(--bs-background);
    border: 1px solid var(--bs-text);
    font-size: 25px;
    padding: 10px;
    width: 90%;
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.arrow-block {
    background-color: #08425e;
    display: inline-block;
    margin-left: 20px;
    padding: 10px;
}

.text-block:hover {
    color: var(--bs-tertiary);
    background-color: #ffffff;
    transition: background-color 0.3s;
    .arrow-block {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        transition: background-color 0.5s;
    }
}

.arrow-block:hover .text-block{
        transition: background-color 0.5s;
}

.card img {
    border: 1px solid var(--bs-tertiary);
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.card {
  border: 0;
  background-color: transparent;
  margin: 0.3em;
}

.card img:hover {
    border: 10px solid #08425e;
    transition: border 0.5s;
}
</style>
