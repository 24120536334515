<template>
    <footer class="footer pt-0">
        <section class="contact py-5 bg-primary text-tertiary">
            <div class="ms-3 text-center">
                    <div class="h1">{{ $t('frame.we_are_open') }}</div>
                    <div class="d-flex justify-content-center text-white mt-3">
                    <table class="h4">
                        <tr>
                            <td class="text-right pr-2">Mo-Do:</td>
                            <td>08-12  /  13-18 Uhr</td>
                        </tr>
                        <tr>
                            <td class="text-right pr-2">Fr:</td>
                            <td>08-12 Uhr</td>
                        </tr>
                    </table>
                    </div>
                </div>
        </section>
        <section class="contact py-3 bg-primary text-white">
            <div class="container">
                <div class="row gy-4">
                <div class="col-lg-4">
                    <div class="d-flex align-items-center">
                    <div class="contact-icon">
                        <i class="fa-solid fa-phone"></i>
                    </div>
                    <div class="ms-3">
                        <h3 class="h5 mb-0"><a style="color: white" v-bind:href="`tel:${$t('frame.phone')}`">{{ $t('frame.phone') }}</a></h3>
                        <p class="h5 mb-0">{{ $t('frame.call_us') }}</p   >
                    </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex align-items-center">
                    <div class="contact-icon">
                        <i class="fa-brands fa-whatsapp"></i>
                    </div>
                    <div class="ms-3">
                        <h3 class="h5 mb-0 text-break"><a style="color:white" v-bind:href="`https://wa.me/4998716579801`">{{ $t('frame.phone') }}</a></h3>
                        <p class="h5 mb-0">{{ $t('frame.whats_app') }}</p>
                    </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex align-items-center">
                    <div class="contact-icon">
                        <font-awesome-icon icon="envelope" />
                    </div>
                    <div class="ms-3">
                        <h3 class="h5 mb-0 text-break"><a style="color:white" v-bind:href="`mailto:${$t('frame.email')}`">{{ $t('frame.email') }}</a></h3>
                        <p class="h5 mb-0">{{ $t('frame.or_write_email') }}</p>
                    </div>
                    </div>
                </div>
                <!--
                <div class="offset-lg-4 col-lg-4 mt-5">
                    <div class="d-flex align-items-top">
                    <div class="contact-icon">
                        <font-awesome-icon icon="clock" />
                    </div>
                    <div class="ms-3">
                        <h3 class="h5 mb-0">{{ $t('frame.we_are_open') }}</h3>
                        <table>
                            <tr>
                                <td class="text-right pr-2">Mo-Do:</td>
                                <td >08-12 / 13-18 Uhr</td>
                            </tr>
                            <tr>
                                <td class="text-right pr-2">Fr:</td>
                                <td>08-12 Uhr</td>
                            </tr>
                        </table>
                    </div>
                    </div>
                </div>
                -->
                </div>
            </div>
        </section>
        <div class="container pt-5">
            <div class="row gy-4">
                <div class="col-lg-4">
                    <h2 class="h5 text-white mb-4 border-bottom">Standort</h2>
                    <div class="text-muted text-sm">
                        <div class="d-flex align-items-center mb-2">
                            <div class="d-inline text-tertiary">
                                <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="2x" />
                            </div>
                            <div class="d-inline mx-2">
                                <a class="text-muted h6" v-bind:href="`https://maps.google.com/maps?hl=de&gl=de&um=1&ie=UTF-8&fb=1&sa=X&ftid=0x479f4bf02887d81d:0x6a2a9b6cb8919ffe`">
                                    Heinrich-Brandt-Straße 25<br/>
                                    91575 Windsbach
                                </a>
                            </div>
                        </div>
                        <div class="d-flex align-items-center text-tertiary">
                            <font-awesome-icon :icon="['fas', 'phone']" size="2x" class="mr-1" />
                            <div class="d-inline mx-2">
                                <a class="text-muted h6" v-bind:href="`tel:${$t('frame.phone')}`"> {{ $t('frame.phone') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <h2 class="h5 text-white mb-4 border-bottom">Folge uns auf</h2>
                    <div class="d-flex ">
                        <ul class="list-inline mb-0 text-tertiary">
                            <li class="list-inline-item">
                                <a class="text-success" href="https://www.tiktok.com/@das_viavitum">
                                    <i class="contact-icon border-tertiary fa-brands fa-tiktok"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-tertiary" href="https://www.youtube.com/channel/UC3AC5puRr9kXBsJv7KOr8XQ">
                                    <i class="contact-icon border-tertiary fa-brands fa-youtube"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-success" href="https://www.instagram.com/das_viavitum/">
                                    <i class="contact-icon border-tertiary fa-brands fa-instagram"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-success" href="https://www.facebook.com/p/Das-Viavitum-100083200307041/">
                                    <i class="contact-icon border-tertiary fa-brands fa-facebook-f"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-success" href="https://wa.me/4998716579801">
                                    <i class="contact-icon border-tertiary fa-brands fa-whatsapp"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4">
                    <h2 class="h5 text-white mb-4 border-bottom">{{$t('frame.newsletter')}}</h2>
                    <p class="text-muted h6">{{$t('frame.newsletter_text')}}</p>
                    <div class="input-group mb-3">
                        <input class="form-control text-muted bg-none border-tertiary" type="text" v-model="email" v-bind:placeholder="$t('frame.email_placeholder')" aria-label="Recipient's username" aria-describedby="button-addon2">
                        <button class="btn btn-tertiary btn-sm" id="button-addon2" :disabled="!enableSubmit || loading" v-on:click="sendNewsletter" type="button"><font-awesome-icon :icon="['fas', 'paper-plane']" /></button>
                    </div>
                    <p class="text-success" v-if="success">Vielen Dank für Deine Anmeldung! Neuigkeiten rund ums Thema Gesundheit landen bald in Deinem Postfach.</p>
                    <p class="text-danger" v-if="duplicate">Du bist schon für den Newsletter angemeldet. Neuigkeiten rund ums Thema Gesundheit landen bald in Deinem Postfach!</p>
                    <p class="text-danger" v-if="error">Oops. Da ist etwas schief gelaufen. Probiers gerne nochmal oder ruf uns einfach an: <a v-bind:href="`tel:${$t('frame.phone')}`">{{ $t('frame.phone') }}</a></p>
                    <h2 class="h5 text-white my-4 border-bottom"></h2>
                    <div class="text-muted text-sm">
                        <div class="d-flex align-items-center mb-2">
                            <div class="d-inline text-tertiary">
                            o
                            </div>
                            <div class="d-inline mx-2">
                                <a class="text-muted h6" v-bind:href="'/impressum'">
                                    Impressum
                                </a>
                            </div>
                        </div>
                        <div class="d-flex align-items-center text-tertiary">
                          <div class="d-inline text-tertiary">
                            o
                          </div>
                          <div class="d-inline mx-2">
                              <a class="text-muted h6" v-bind:href="'/datenschutz'">
                                  Datenschutz
                              </a>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyrights">
            <div class="container text-center py-4">
                <p class="mb-0 text-muted text-sm">&copy; {{ year }}, Das Viavitum</p>
            </div>
        </div>
    </footer>
</template>

<script>
import axios from '../services/axiosConfig';

export default {
    name: 'FooterLayout',
    data: () => ({
        year: new Date().getFullYear(),
        success: false,
        duplicate: false,
        error: false,
        email: '',
        loading: false
    }),
    computed: {
        enableSubmit() {
            return this.email !== '' && (!this.duplicate) && (!this.success);
        }
    },
    methods: {
        sendNewsletter() {
            this.loading = true;
            this.success = false;
            this.duplicate = false;
            this.error = false;
            axios.post('newsletter/', {
                email: this.email
            }).then((response) => {
                if (response.data.status === 1) {
                    this.success = true;
                } else if (response.data.status === 2) {
                    this.duplicate = true;
                } else {
                    this.error = true;
                }
                this.loading = false;
            }).catch((error) => {
                console.error(error);
                this.error = true;
            });
        }
    },
    mounted() {
    }
};
</script>
<style scoped>
a {
    text-decoration: none;
}

.fa-youtube:hover{
    color: #ff0000;
}

.fa-tiktok:hover{
    color: white;
}

.fa-instagram:hover{
    color: #f09433;
}

.fa-facebook-f:hover{
    color: #3b5998;
}

.fa-whatsapp:hover{
    color: #25d366;
}

.border-bottom {
    border-bottom: 2px solid #b0c34a !important;
}
</style>
