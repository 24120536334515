<template>
    <div class="container">
      <div class="row gy-5">
            <div class="col-lg-9 col-sm-12 mx-auto text-center">
                <p class="text-primary mb-3 custom-text-001">Euer Therapiezentrum</p>
                <img class="img-fluid mb-3" src="../../assets/img/decorations/line_green_horizontal.png" alt="Liniendekoration">
                <p class="text-primary mb-3 custom-text-002">
                    Logopädie <span class="text-tertiary">|</span> Physiotherapie <span class="text-tertiary">|</span> Ergotherapie
                </p>
                <p class="text-muted custom-text-003">
                    Wir machen Leben
                </p>
                <div>
                  <img class="img-fluid mb-3" src="../../assets/img/decorations/noticeably_different.svg" alt="Fleckdekoration">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
};
</script>
<style scoped>

.custom-text-001 {
    font-size: 5vw;
    white-space: nowrap;
}

.custom-text-002 {
    font-size: 2.5vw;
}

.custom-text-003 {
    font-size: 6vw;
    white-space: nowrap;
}

@media (min-width: 1892px) {
    .custom-text-001 {
        font-size: 90px;
    }

    .custom-text-002 {
        font-size: 36px;
    }

    .custom-text-003 {
        font-size: 100px;
    }
}

@media (max-width: 576px) {
    .custom-text-001 {
        font-size: 8vw;
    }

    .custom-text-002 {
        font-size: 5vw;
    }

    .custom-text-003 {
        font-size: 8vw;
    }

    .text-block {
        padding-left: 2.5vw;
        padding-right: 2.5vw;
    }
}
</style>
