<template>
    <div class="container">
        <div style="position: relative; display: inline-block;">
            <img class="img-fluid mb-3" src="../../assets/img/decorations/here_you_find_us.svg" alt="Fleckdekoration">
        </div>
        <div class="address-container mb-5">
            <i class="fa-solid text-primary fa-location-dot"></i>
            <p class="fs-4 text-muted">Heinrich Brandt Str. 25<br>91575 Windsbach</p>
        </div>
        <div style="position: relative; display: inline-block;">
            <img class="img-fluid mb-3" src="../../assets/img/decorations/contact.svg" alt="Fleckdekoration">
        </div>
        <div class="address-container mb-5">
            <i class="fa-solid text-primary fa-phone"></i>
            <p class="fs-4 text-muted">09871 657 98 01<br>info@das-viavitum.de</p>
        </div>
        <div style="position: relative; display: inline-block;">
            <img class="img-fluid mb-3" src="../../assets/img/decorations/open_hours.svg" alt="Fleckdekoration">
        </div>
        <div class="address-container">
            <i class="fa-solid text-primary fa-clock"></i>
            <p class="fs-4 text-muted"><span class="text-primary">MO–DO: </span><br>8-12 Uhr / 13-18 Uhr<br><span class="text-primary">Fr: </span>8–12 Uhr<br>Und nach Vereinbarung.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
};
</script>

<style scoped>
.address-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fa-solid {
    font-size: 80px;
    margin-right: 20px;
}

@media screen and (max-width: 1399px) {
    .fa-solid {
        font-size: 3vw;
    }
}

@media screen and (max-width: 991px) {
    .fa-solid {
        font-size: 3vw;
    }
}

@media screen and (max-width: 767px) {
    .fa-solid {
        font-size: 7vw;
    }
}

@media screen and (max-width: 575px) {
    .fa-solid {
        font-size: 10vw;
    }
}
</style>
