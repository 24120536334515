<template>
    <!-- navbar-->
    <header class="header" ref="header">
        <!-- Primary Navbar-->
        <nav class="navbar navbar-expand-lg navbar-light py-4 border-bottom border-gray bg-white pt-0 pb-2">
            <div class="container d-flex align-items-center justify-content-between">
                <a href="/"
                class="navbar-brand">
                  <img src="../assets/img/logo_neu.png" alt="logo" width="360">
                </a>
                <button class="navbar-toggler" type="button" @click="toggleNavBar"><span
                        class="navbar-toggler-icon"></span></button>
                <!-- telephone and opening hours -->
                <ul class="list-inline mb-0 mt-3 d-none d-lg-block">
                    <li class="list-inline-item mr-4">
                        <div class="d-flex">
                            <span class="h1 text-muted">
                                <i class="fa-regular fa-phone"></i>
                            </span>
                            <div class="ms-2">
                                <h6 class="text-muted text-uppercase mb-0 text-sm">
                                    <a class="text-muted" v-bind:href="`tel:${$t('frame.phone')}`">{{ $t('frame.phone')
                                    }}</a>
                                </h6>
                                <p class="small text-gray mb-0">{{ $t('frame.call_us') }}</p>
                            </div>
                        </div>
                    </li>
                    <li class="list-inline-item mr-4">
                        <div class="d-flex">
                            <span class="h1 text-muted">
                                <i class="far fa-clock"></i>
                            </span>
                            <div class="ms-2">
                                <h6 class="text-muted text-uppercase mb-0 text-sm">Telefon</h6>
                                <table class="small text-gray">
                                    <tr>
                                        <td class="text-right pr-2">Mo-Fr:</td>
                                        <td>08-12 Uhr</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </li>
                    <li class="list-inline-item mr-4">
                        <div class="d-flex">
                            <span class="h1 text-muted">
                                <i class="fa-regular fa-house"></i>
                            </span>
                            <div class="ms-2">
                                <h6 class="text-muted text-uppercase mb-0 text-sm">Praxis</h6>
                                <table class="small text-gray">
                                    <tr>
                                        <td class="pr-2">Mo-Do:</td>
                                        <td>08-12 Uhr</td>
                                    </tr>
                                    <tr>
                                        <td class="pr-2"></td>
                                        <td>13-18 Uhr</td>
                                    </tr>
                                    <tr>
                                        <td class="pr-2">Fr:</td>
                                        <td>08-12 Uhr</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
        <!-- Secondary Navbar                -->
        <nav class="navbar navbar-expand-lg navbar-light py-2 shadow-sm bg-white text-center">
                <div v-bind:class="{ collapse: !show_nav }" class="navbar-collapse py-3 py-lg-0" id="navbarSupportedContent">
                    <ul class="navbar-nav mx-auto">
                      <li class="nav-item">
                        <router-link to="/" v-slot="{ navigate, isActive }" custom>
                          <span @click="navigate" class="nav-link letter-spacing-0" :class="{ active: isActive }" style="font-size: 1.1rem !important">{{ $t('frame.about') }}</span>
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/leistungen" v-slot="{ navigate, isActive }" custom>
                          <span @click="navigate" class="nav-link letter-spacing-0" :class="{ active: isActive }" style="font-size: 1.1rem !important">{{ $t('frame.services') }}</span>
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/preise" exact v-slot="{ navigate, isActive }" custom>
                          <span @click="navigate" class="nav-link letter-spacing-0" :class="{ active: isActive }" style="font-size: 1.1rem !important">{{ $t('frame.praise') }}</span>
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/karriere" exact v-slot="{ navigate, isActive }" custom>
                          <span @click="navigate" class="nav-link letter-spacing-0" :class="{ active: isActive }" style="font-size: 1.1rem !important">{{ $t('frame.career') }}</span>
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/kontakt" exact v-slot="{ navigate, isActive }" custom>
                          <span @click="navigate" class="nav-link letter-spacing-0" :class="{ active: isActive }" style="font-size: 1.1rem !important">{{ $t('frame.contact') }}</span>
                        </router-link>
                      </li>
                      <li class="nav-item">
                          <router-link to="/appointments" exact v-slot="{ navigate, isActive }" custom>
                            <span @click="navigate" class="nav-link letter-spacing-0" :class="{ active: isActive }" style="font-size: 1.1rem !important;">{{ $t('frame.appointment') }}</span>
                          </router-link>
                      </li>
                      <li class="nav-item">
                        <a
                          href="https://fast-ant-f48.notion.site/6823e76b924b4f1299477b599933559c?v=6164ec662c864ec989c1d40f3a206616"
                          class="nav-link letter-spacing-0"
                          style="font-size: 1.1rem !important; background-color: #094765 !important"
                          target="_blank">
                          {{ $t('frame.aktuellt') }}
                        </a>
                      </li>
                    </ul>
                </div>

              </nav>
    </header>
</template>

<script>
export default {
    name: 'HeaderLayout',
    mounted() {
    },
    data: () => ({
        show_nav: false
    }),
    methods: {
        toggleNavBar() {
            this.show_nav = !this.show_nav;
        },
        dropdownClicked() {
            this.$refs.sbout_dropdown.hide();
        }
    }
};
</script>

<style>
.nav-item {
    width: 184px;
}

@media (max-width: 1400px) {
    .nav-item {
        width: 159px;
    }
}

@media (max-width: 1200px) {
    .nav-item {
        width: 134px;
    }
}

@media (max-width: 992px) {
    .nav-item {
        width: 100%;
    }
}

.dropdown-menu {
    border-radius: 15px !important;
    z-index: 1000;
}

.header {
  z-index: 1000;
}
</style>
