<template>
    <layout-default :sections="sections" :activeSection="activeSection">
        <section class="bg-white py-1" id="home" data-description="Willkommen">
          <div class="container d-flex align-items-center">
              <video loading="lazy" class="object-fit-cover" autoplay muted loop playsinline width="100%" poster="/willkommen.jpg">
                  <source src="/willkommen_sm.mp4" media="(max-width: 400px)" type="video/mp4">
                  <source src="/willkommen_md.mp4" media="(max-width: 550px)" type="video/mp4">
                  <source src="/willkommen_lg.mp4" media="(max-width: 930px)" type="video/mp4">
                  <source src="/willkommen_xl.mp4" type="video/mp4">
                  Your browser does not support the video tag.
              </video>
          </div>
          <div class="container">
            <you-therapy />
          </div>
        </section>
        <section class="bg-light py-1">
            <!-- Contact Information and Info-->
            <div class="container mt-1">
                <div class="row">
                    <div class="col-lg-7">
                        <our-desire />
                    </div>
                    <div class="col-lg-5">
                        <contact-information />
                    </div>
                </div>
            </div>
            <div class="container">
              <div class="colored-block mt-3">
                <p class="fs-4 text-tertiary pb-3" >Du erreichst uns über  Telefon, E-Mail oder WhatsApp. Wir freuen uns auf dich!</p>
                <router-link to="/appointments#appointment-reservation" exact v-slot="{ navigate, isActive }" custom>
                  <div @click="navigate" :class="{ active: isActive }"  class="text-text text-block">
                    zur Reservierung
                    <!--
                    <i class="text-white fa-light fa-right arrow-block"></i>
                    -->
                  </div>
                </router-link>
              </div>
            </div>
        </section>
        <!-- Therapy Services -->
        <section class="bg-white py-1">
            <div class="container">
                <therapy-services />
            </div>
        </section>
        <!-- Our Principle -->
        <section class="py-2 bg-light" id="leitbild" data-description="Leitbild">
          <our-principle />
        </section>
        <!-- About Section-->
        <section class="bg-white py-2" id="about" data-description="Über uns">
            <div class="container">
                <header-with-line header="Lerne uns kennen " class="mb-5"/>
            </div>
            <div class="container">
                <div class="row gy-5 d-flex justify-content-center">
                      <div class="col-lg-4 col-md-9">
                        <div class="card border-0">
                            <div class="card-body p-0 shadow-sm d-flex flex-column justify-content-center text-center">
                                <div class="position-relative"><img loading="lazy" class="img-fluid" src="../assets/img/team/ralph.jpg" alt="Ralph"></div>
                                <div class="p-4">
                                    <h6 class="mb-0">Ralph</h6>
                                    <p class="text-muted text-sm mb-0">Inhaber</p>
                                </div>
                                <div class="card-back">
                                    <h4>
                                        🦸‍♀️ Therapeuten 🦸‍♂️ sind hartnäckiger als 🤕 Beschwerden!
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <get-to-know />
                    </div>
                </div>
            </div>
        </section>
        <!-- Statistics Section-->
        <section class="bg-white py-1">
            <div class="container text-tertiary bg-primary border-top-bottom  py-5 px-5">
                <div class="row gy-4">
                    <div class="col-xl-3 col-md-6">
                        <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                            <h2 class="text-lg text-white me-3 mb-0">1</h2>
                            <p class="lead lh-sm mb-0">Motiviertes<br />Team</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                            <h2 class="text-lg text-white me-3 mb-0">100+</h2>
                            <p class="lead lh-sm mb-0">Jahre<br />Erfahrung</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                            <h2 class="text-lg text-white me-3 mb-0">100+</h2>
                            <p class="lead lh-sm mb-0">Fortbildungen <br /> und Zertifikate</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                            <h2 class="text-lg text-white me-3 mb-0">1000+</h2>
                            <p class="lead lh-sm mb-0">Glückliche<br />Patienten</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Our Team -->
        <section class="bg-light py-1">
            <div class="container text-center">
                <our-team />
                <div class="row gy-4">
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative"><img loading="lazy" class="img-fluid" src="../assets/img/team/carolin.jpg"
                                        alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Carolin</h6>
                                    <p class="text-muted text-sm mb-0">Frontoffice</p>
                                </div>
                                <div class="card-back">
                                    <h4>Sie hat hier den Plan: „🤍💙💚“</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img loading="lazy" class="img-fluid" src="../assets/img/team/helena.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Helena</h6>
                                    <p class="text-muted text-sm mb-0">Buchhaltung</p>
                                </div>
                                <div class="card-back">
                                    <h4>Engel 😇 sieht man nicht, doch sind sie 😶‍🌫️ da!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative"><img loading="lazy" class="img-fluid" src="../assets/img/team/stefanie.jpg"
                                        alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Stefanie</h6>
                                    <p class="text-muted text-sm mb-0">Backoffice</p>
                                </div>
                                <div class="card-back">
                                    <h4>Sie is(s)t 🍭 Zucker 🍩 süß und 🤓 raffiniert</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img loading="lazy" class="img-fluid" src="../assets/img/team/anne.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Anne</h6>
                                    <p class="text-muted text-sm mb-0">Frontoffice / Marketing</p>
                                </div>
                                <div class="card-back">
                                    <h4>Wer lernt 👩‍🎓 hier von 🤓 wem?</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative"><img loading="lazy" class="img-fluid" src="../assets/img/team/christian.jpg"
                                        alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Christian</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeut</p>
                                </div>
                                <div class="card-back">
                                    <h4>Er hat den absoluten 😎 Durchblick!✌</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative"><img loading="lazy" class="img-fluid" src="../assets/img/team/alexander.jpg"
                                        alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Alexander</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeut</p>
                                </div>
                                <div class="card-back">
                                    <h4>Mit viel Charme 😉 von Fuß bis über 🙋‍♂️ Schulter Arm!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative"><img loading="lazy" class="img-fluid" src="../assets/img/team/corina.jpg"
                                        alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Corina</h6>
                                    <p class="text-muted text-sm mb-0">Ergotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4>🧠 Die Queen des Neurofeedback 💪 kennt dein Potential!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative"><img loading="lazy" class="img-fluid" src="../assets/img/team/marita.jpg"
                                        alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Marita</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4>🤣 Ihr Strahlen 🌞 reißt jeden mit!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img loading="lazy" class="img-fluid" src="../assets/img/team/maria.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Maria</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4>Wow die Frau 👩 hat 👩‍🎓 Know-how!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img loading="lazy" class="img-fluid" src="../assets/img/team/dominik.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Dominik</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeut</p>
                                </div>
                                <div class="card-back">
                                    <h4>💡Unser Mann für Evidenz und Osteopathie Expertise 📈</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img loading="lazy" class="img-fluid" src="../assets/img/team/annika.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Annika</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4>Charmant 😉 versprüht, sie frischen Wind 🌬️</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img loading="lazy" class="img-fluid" src="../assets/img/team/keisha.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Keisha</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4>Ob jung 👧 ob alt ihr lächeln 😀 lässt niemand kalt!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img loading="lazy" class="img-fluid" src="../assets/img/team/marie.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Marie</h6>
                                    <p class="text-muted text-sm mb-0">Ergotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4> 👩🎨 Bunter und vielfältiger als ein Regenbogen🌈</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img loading="lazy" class="img-fluid" src="../assets/img/team/anna.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Anna</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4>Sie bring „🤩“ ins Leben!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img loading="lazy" class="img-fluid" src="../assets/img/blanco.jpg" alt="blanco Bild">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Wird dies dein Name?</h6>
                                    <p class="text-muted text-sm mb-0">Dein Abenteuer wartet</p>
                                </div>
                                <div class="card-back">
                                    <h4>😃 lass dich überraschen 😇</h4>
                                    <a class="text-white" href="/karriere">Jetzt kennenlernen</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
         <!-- Google Street View -->
        <section class="bg-white py-1" id="praxis" data-description="Praxis">
            <div class="container">
                <header-with-line header="Schau dich um" class="mb-5"/>
                <div>
                  <p class="fs-4">
                    In unserem Therapiezentrum erwartet dich eine moderne und einladende Umgebung, die ganz auf deine Bedürfnisse abgestimmt ist. Hier bekommst du einen ersten Eindruck von unseren Therapieräumen, unserer Ausstattung und der Atmosphäre, die wir geschaffen haben, um deinen Aufenthalt so angenehm wie möglich zu gestalten. Schau dich um und lerne schon jetzt die Räume kennen, in denen du bald aktiv an deinen Fortschritten arbeiten wirst.
                  </p>
                </div>
            </div>

              <div class="container">
                <!--
                  <div class="embed-responsive embed-responsive-16by9">
                      <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!4v1717402671240!6m8!1m7!1sCAoSLEFGMVFpcE1XZW9CNlNET3JubFI4SmlsNzF3bExKb2dOYXd1MUhFcV9LWDVJ!2m2!1d49.250083028986!2d10.831348216637!3f348.7568179818219!4f-7.638439729193095!5f0.7820865974627469" style="border:0;" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                -->
                  <tour-bilder />
              </div>
        </section>
        <!-- Partner Section-->
        <section class="bg-light py-1" id="partner" data-description="Partner">
            <header-with-line header="Gemeinsam mehr erreichen mit starken Partnern" class="mb-5"/>
            <div class="container text-white">
                <our-partners />
            </div>
        </section>
    </layout-default>
</template>

<script>
import { collectSections, observeSections } from '../utils/sectionCollector';
import LayoutDefault from '../layouts/LayoutDefault.vue';
import YouTherapy from '../components/About/YouTherapy.vue';
import ContactInformation from '../components/About/ContactInformation.vue';
import OurDesire from '../components/About/OurDesire.vue';
import TherapyServices from '../components/About/TherapyServices.vue';
import GetToKnow from '../components/About/GetToKnow.vue';
import OurTeam from '../components/About/OurTeam.vue';
import OurPartners from '../components/About/OurPartners.vue';
import OurPrinciple from '../components/About/OurPrinciple.vue';
import HeaderWithLine from '../components/Ui/HeaderWithLine.vue';
import TourBilder from '../components/About/TourBilder.vue';

export default {
    name: 'About',
    components: {
        LayoutDefault,
        YouTherapy,
        ContactInformation,
        OurDesire,
        TherapyServices,
        GetToKnow,
        OurTeam,
        OurPartners,
        OurPrinciple,
        HeaderWithLine,
        TourBilder
    },
    data() {
        return {
            sections: [],
            activeSection: ''
        };
    },
    methods: {
        isLargeScreen() {
            return window.innerWidth >= 1589;
        },
        updateActiveSection(id) {
            this.activeSection = id;
        }
    },
    mounted() {
        window.addEventListener('resize', this.$forceUpdate);
        this.sections = collectSections();
        observeSections(this.sections, this.updateActiveSection);
    }
};
</script>

<style scoped>
.list-check li {
    padding-left: 1.5rem;
    position: relative;
}

.list-check li i {
    color: #084765;
}

.hero {
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.card {
    perspective: 1000px;
}

.card:hover .card-body {
    transform: rotateY(180deg);
}

.card-body {
    transform-style: preserve-3d;
    transition: transform 0.5s;
}

.card-front,
.card-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.card-front {
    z-index: 2;
    transform: rotateY(0deg);
}

.card-back {
    transform: rotateY(180deg);
    background-color: #084765;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    padding: 20px;
    z-index: 1;
    /* Den Text auf der Rückseite sichtbar machen */
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
.border-top-bottom {
    border-top: 5px solid #b3c935;
    border-bottom: 5px solid #b3c935;
}

.colored-block {
    background-color: #08425e;
    padding: 20px;
    border-top: 5px solid #b3c935;
    border-bottom: 5px solid #b3c935;
    position: relative;
    text-align: center;
}

.text-block {
    font-weight: bold;
    color: var(--bs-text);
    background-color: var(--bs-background);
    padding: 15px;
    padding-left: 40px;
    padding-right: 40px;
    border: 2px solid var(--bs-text);
    border-radius: 50px;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    font-size: 1.6em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.text-block:hover {
    color: var(--bs-tertiary);
    background-color: #ffffff;
    transition: background-color 0.3s;
    .arrow-block {
        transition: background-color 0.5s;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    }
}

.arrow-block {
    background-color: #08425e;
    padding: 20px;
    border-radius: 50px;
    display: inline-block;
    margin: 0 auto;
    font-size: 1.2rem;
    margin-left: 20px;
    cursor: pointer;
}

.border-video {
    border: 3px solid #b3c935;
    border-radius: 30px;
}

@media screen and (max-width: 576px) {
    .text-block {
        padding-left: 2.5vw;
        padding-right: 2.5vw;
    }

}

@media (hover: none) and (pointer: coarse) {
  .card-body:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    clip-path: polygon(0 10%, 100% 0%, 0 100%);
    background: linear-gradient(135deg, #ffffff 10%, #07425e 90%);
    background-color: #08425e;
  }
}

@media (hover: none) and (pointer: coarse) and (max-width: 576px) {
  .card-body:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    clip-path: polygon(0 10%, 100% 0%, 0 100%);
    background: linear-gradient(135deg, #ffffff 10%, #07425e 90%);
    background-color: #08425e;
  }
}

</style>
