<template>
    <div>
        <div class="title-container">
            <div class="text-left-container">
                <p class="fs-1 text-bold text-primary mb-4">Wir...</p>
            </div>
        </div>
        <ul class="custom-list text-primary fs-3 ml-4">
            <li>
                <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                <div>
                  <div>spezialisieren uns auf Neurorehabilitation</div>
                  <div class="text-muted fs-4">
                    umfassende Therapie für neurologische Herausforderungen.
                  </div>
                </div>
            </li>
            <li>
              <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                <div>
                  <div>arbeiten interdisziplinär zusammen</div>
                  <div class="text-muted fs-4">
                    vereinte Expertise für optimale Therapieerfolge.
                  </div>
                </div>
            </li>
            <li>
              <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                <div>
                  <div>bieten Flexibilität in der Therapie</div>
                  <div class="text-muted fs-4">
                    individuell angepasst an deine persönlichen Bedürfnisse und Therapieziele.
                  </div>
                </div>
            </li>
            <li>
              <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                <div>
                  <div>nutzen innovative Technik und Methoden</div>
                  <div class="text-muted fs-4">
                    moderne Ansätze für die bestmögliche Behandlung.
                  </div>
                </div>
            </li>
            <li>
              <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                <div>
                  <div>setzen auf einen nachhaltigen Therapieansatz</div>
                  <div class="text-muted fs-4">
                    spürbar anders durch Wertschätzung und individuelle Fortschritte.
                  </div>
                </div>
            </li>
        </ul>
        </div>
</template>

<script>
export default {
    name: '',
    components: {
    },
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
};
</script>

<style scoped>
.custom-text-001 {
    font-family: 'HP Simplified';
    font-size: 40px;
    white-space: nowrap;
}

.custom-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.custom-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.custom-list img {
    height: 40px; /* Adjust the height as needed */
    margin-right: 10px;
}

.title-container {
    margin-inline: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-container h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%; /* Optional: ensure the text takes full width for centering */
}

.title-container img {
    width: 70%; /* Adjust the percentage to match the desired width */
    margin: 10px; /* Adjust the margin as needed */
    min-width: 100px; /* Set a minimum width to prevent the image from getting too small */
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
}
.text-left-container {
    align-self: flex-start; /* Ensures the text container aligns to the left */
}

</style>
