<template>
    <div>
        <header-layout />
        <top-sub-navigation v-if="sections.length > 0" :sections="sections" :activeSection="activeSection" class="top-sub-navigation" />
          <slot />
        <footer-layout />
        <up-button />
    </div>
</template>

<script>
import HeaderLayout from './LayoutHeader.vue';
import FooterLayout from './LayoutFooter.vue';
import TopSubNavigation from './TopSubNavigation.vue';
import UpButton from '../components/Ui/UpButton.vue';

export default {
    name: 'DefaultLayout',
    components: {
        HeaderLayout,
        FooterLayout,
        TopSubNavigation,
        UpButton
    },
    props: {
        sections: {
            type: Array,
            default: () => []
        },
        activeSection: {
            type: String,
            default: ''
        }
    },
    mounted() {
        document.title = 'Das Viavitum | Das spürbar andere Therapiezentrum in Windsbach';
    }
};
</script>
<style>
.top-sub-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
