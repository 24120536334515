import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API
});

const MAX_RETRIES = 3;
const RETRY_DELAY = 3000;

axiosInstance.interceptors.request.use(
    (config) => {
        const newConfig = { ...config, retryCount: config.retryCount || 0 };
        return newConfig;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { config } = error;
        const updatedConfig = { ...config };
        if (
            !updatedConfig.retryCount
            || updatedConfig.retryCount < MAX_RETRIES
        ) {
            updatedConfig.retryCount += 1;
            console.warn(`Retrying request (${updatedConfig.retryCount}/${MAX_RETRIES})...`);
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
            return axiosInstance(updatedConfig);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
